<template>
 <div class="body">
  <div  class="new_header">
      <div>
        <img src="@/assets/pc/logo.png" alt="logo" style="width:149px;height:30px;">&nbsp;
      </div>
      <div class="new_header_right">
        <div class="right_block webstie" @click="redirectToViwoods">viwoods.com</div>

        <div class="right_block">
          <!-- <div v-if="deviceList.length>0">{{ll[languageId].current_device}}</div> -->
          <div >{{ll[languageId].file_time}}</div>
          <div style="margin-left: 5px;">
            <a-dropdown>
              <div class="ant-dropdown-link"  style="display: flex;color:#000;">
                <div style="position: relative;">
                    <div>{{ timeName }}</div>
                </div> 
                <div class="block_d" v-if="deviceList.length>1">
                  <img src="@/assets/pc/open.png" alt="logo" style="width:16px;height:16px;">&nbsp;
                </div>
              </div>
              <a-menu slot="overlay" @click="handleTime" v-if="ll[languageId].timeList.length>1">
                <a-menu-item  v-for="item in ll[languageId].timeList" :key="item.id" style="height: 50px;">
                   <div style="position: relative;width: 180px;" :style="item.id==timeId ? 'color:#275B52;':''">
                    <div>{{ item.name }}</div>
                    <div style="font-size: 9px;color:#999;position: absolute;margin-top:-5px;" :style="item.id==timeId ? 'color:#275B52;':''">{{item.desc}}</div>
                  </div> 
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>

        <div class="right_block">
          <!-- <div v-if="deviceList.length>0">{{ll[languageId].current_device}}</div> -->
          <div v-if="deviceList.length<1">{{ll[languageId].current_no_device}}</div>
          <div style="margin-left: 5px;">
            <a-dropdown>
              <div class="ant-dropdown-link"  style="display: flex;color:#000;">
                <div style="position: relative;width: 100px;">
                    <div>{{ deviceName }}</div>
                    <div style="font-size: 9px;color:#999;position: absolute;">[{{getMachineNumbers()}}]</div>
                </div> 
                <div class="block_d" v-if="deviceList.length>1">
                  <img src="@/assets/pc/open.png" alt="logo" style="width:16px;height:16px;">&nbsp;
                </div>
              </div>
              <a-menu slot="overlay" @click="handleDevice" v-if="deviceList.length>1">
                <a-menu-item  v-for="item in deviceList" :key="item.machineNumber" style="height: 50px;">
                   <div style="position: relative;width: 90px;" :style="item.machineNumber==getMachineNumbers() ? 'color:#275B52;':''">
                    <div>{{ item.modelName }}</div>
                    <div style="font-size: 9px;color:#999;position: absolute;margin-top:-5px;" :style="item.machineNumber==getMachineNumbers() ? 'color:#275B52;':''">[{{item.machineNumber}}]</div>
                  </div> 
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
        <div class="right_block">
          <div style="margin-left: 5px;">
            <a-dropdown>
              <div class="ant-dropdown-link"  style="display: flex;">
                {{ languageName }} 
                <div class="block_d">
                  <img src="@/assets/pc/open.png" alt="logo" style="width:16px;height:16px;">&nbsp;
                </div>
              </div>
              <a-menu slot="overlay" @click="handleLanguage">
                <a-menu-item  v-for="item in languageList" :key="item.id"   :style="languageName==item.name ? 'color:#275B52;':''">
                   {{ item.name }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
        <div class="right_block" @click="userLogout">
          <div>
            <img src="@/assets/pc/exit.png" alt="logo" style="width:12px;height:13px;">&nbsp;
          </div>
          <div class="block_d">{{ll[languageId].exit}}</div>
        </div>
      </div>
  </div>


   <!-- 添加加载中的指示器 -->
  <!-- 主要内容 -->
    <div class="c-left" id="sidebar" style="min-height: 500px;overflow: hidden;"> 

        <div style="height: 75%;">
          <div class="left-item "  v-for="item in fileTypeList" :key="item.type" :class="item.dir == fileTypeListId ? 'left-item-on' : ''" @click="changeId(item);">
            <div class="left-item-l">
              <img v-if="item.dir=='/paper/'" src="@/assets/pc/paper-i.png" alt="logo" style="width:25px;height:25px;">
              <img v-if="item.dir=='/meeting/'" src="@/assets/pc/meeting-i.png" alt="logo" style="width:25px;height:25px;">
              <img v-if="item.dir=='/picking/'" src="@/assets/pc/picking-i.png" alt="logo" style="width:25px;height:25px;">
              <img v-if="item.dir=='/learning/'" src="@/assets/pc/learning-i.png" alt="logo" style="width:25px;height:25px;">
              <img v-if="item.dir=='/memo/'"    src="@/assets/pc/memo-i.png" alt="logo" style="width:25px;height:25px;">
              <img v-if="item.dir=='/picture/'" src="@/assets/pc/picture-i.png" alt="logo" style="width:25px;height:25px;">
              <img v-if="item.dir=='/daily/'" src="@/assets/pc/daily-i.png" alt="logo" style="width:25px;height:25px;">
              <img v-if="item.dir=='/other/'" src="@/assets/pc/other-i.png" alt="logo" style="width:25px;height:25px;">
              
              <div style="margin-left: 20px;">
                  <div v-if="item.dir=='/paper/'">{{ll[languageId].paper_name}}</div>
                  <div v-if="item.dir=='/meeting/'">{{ll[languageId].meeting_name}}</div>
                  <div v-if="item.dir=='/picking/'">{{ll[languageId].picking_name}}</div>
                  <div v-if="item.dir=='/learning/'">{{ll[languageId].learning_name}}</div>
                  <div v-if="item.dir=='/memo/'">{{ll[languageId].memo_name}}</div>
                  <div v-if="item.dir=='/picture/'">{{ll[languageId].picture_name}}</div>
                  <div v-if="item.dir=='/daily/'">{{ll[languageId].daily_name}}</div>
                  <div v-if="item.dir=='/other/'">{{ll[languageId].other_name}}</div>
              </div>  
            </div>
            <div class="left-item-r">{{item.num}}</div>
        </div>
        </div>
        <div style="padding-left: 25px;padding-right: 25px;padding-bottom: 25px;height: 25%;align-items: end;color:#292D33;font-size: 14px;">{{ll[languageId].outTime.replace("%s",timeName)}}</div>
    </div>
    <div class="c-right">
      <div style="position: fixed;top:88px;left:200px;width: calc(100% - 200px);z-index: 1000;background-color: #fff;
    padding: 30px 25px 0 25px;min-width: 900px;display: flex;    flex-direction: column;" class="">
         <div style="display: flex;justify-content: space-between;width: 100%;"> 
          <div style="display: flex;justify-content: flex-start;align-items: center;">
            <!-- <div>笔记</div> -->
            <div style="display: flex;justify-content: flex-start;">
              <div v-if="preDir" style="color:#000;font-size: 16px;font-weight: 600;cursor: pointer;" class="border_right" @click="goPreDir()">{{ll[languageId].return}}</div>
              <div style="color:#000;font-size: 16px;font-weight: 600;" :style="preDir?'margin-left:15px':''">
                <span>{{dir.replace(/^\/|\/$/g, '')}}</span> <span style="margin-left: 5px;">({{ resData.length}})</span></div>
            </div>

        

           <div style="margin-left: 25px;position: relative;">
            <a-input  class="custom-input"
                      size="large" 
                      v-model="fileName" 
                      :placeholder="ll[languageId].search_all" 
                      @keyup.enter="goto()" 
                      @focus="handleFocus"
                      @blur="handleBlur"
                    >  
            </a-input>
            <div style="position: absolute;top:8px;right:10px;cursor: pointer;" @click="goto()">
              <img  src="@/assets/pc/search.png" alt="logo" style="width:17px;height:17px;">
            </div>
          </div>
         </div>

         <div style="display: flex;align-items: center;justify-content: flex-end;">

          <div v-if="selectedFruits.length>0" style="color: #000;font-size: 14px;">{{ll[languageId].selected}}{{selectedFruits.length}}{{ll[languageId].selected_file}}</div>
          <div v-if="selectedFruits.length>0" style="margin-left: 20px; display: flex;cursor: pointer;">
            <!-- <div style="display: flex;justify-content: flex-start;background-color: #000;border-radius: 2px;align-items: center;">
              <div @click="handleDownload('')" style="color:#fff;font-size: 13px;font-weight: 600;height: 36px;padding-left:20px;padding-right: 20px;display: flex;align-items: center;justify-content: center ;" class="border_right_d">{{ll[languageId].download}}</div>
              <div style="color: #fff;font-size: 12px;margin-top:-3px;">|</div>
              <div @click="openRemoveModal('')" style="color:#fff;font-size: 13px;font-weight: 600;height: 36px;padding-left:20px;padding-right: 20px;display: flex;align-items: center;justify-content: center ;">{{ll[languageId].delete}}</div>
            </div> -->

            <div v-if="selectedFruits.length>0" style="color:#fff;font-size: 13px;margin-left: 15px;padding:0 10px;height: 36px;display: flex;font-weight: 500;align-items: center;justify-content: center ;background-color: #000;border-radius: 2px;" @click="handleDownload('')">{{ll[languageId].download}}</div>
            <div v-if="selectedFruits.length>0" style="color:#fff;font-size: 13px;margin-left: 15px;padding:0 10px;min-width: 67px;height: 36px;display: flex;font-weight: 500;align-items: center;justify-content: center ;background-color: #000;border-radius: 2px;" @click="openRemoveModal('')">{{ll[languageId].delete}}</div>
            <div v-if="selectedFruits.length>0 && dir !='/other/'" style="color:#fff;font-size: 13px;margin-left: 15px;padding:0 10px;min-width: 67px;height: 36px;display: flex;font-weight: 500;align-items: center;justify-content: center ;background-color: #000;border-radius: 2px;" @click="showDeviceListModal('')">{{ll[languageId].send}}</div>
          </div>
          <div  style="margin-left: 30px;margin-right: 10px;cursor: pointer;" @click="goto(0)">
            <img  src="@/assets/pc/refresh.png" alt="logo" style="width:25px;height:25px;">
           </div>
           <div v-if="dir == '/paper/'"  @click="handleUploadType2" style="cursor: pointer;width:160px;height: 42px;background: #275B52;border-radius: 2px;display: flex;align-items: center;justify-content: center;margin-left: 20px;">
              <img  src="@/assets/pc/import.png" alt="logo" style="width:21px;height:21px;">
              <div style="color:#fff;font-size: 16rpx;margin-left: 5px;">{{ll[languageId].import_paper}}</div>
           </div>
           <div v-if="dir == '/learning/'"  @click="handleUploadType1" style="cursor: pointer;width:160px;height: 42px;background: #275B52;border-radius: 2px;display: flex;align-items: center;justify-content: center;margin-left: 20px;">
              <img  src="@/assets/pc/import.png" alt="logo" style="width:21px;height:21px;">
              <div style="color:#fff;font-size: 16rpx;margin-left: 5px;">{{ll[languageId].import_learning}}</div>
           </div>
           <div v-if="dir == '/meeting/'"  @click="handleUploadType1" style="cursor: pointer;width:160px;height: 42px;background: #275B52;border-radius: 2px;display: flex;align-items: center;justify-content: center;margin-left: 20px;">
              <img  src="@/assets/pc/import.png" alt="logo" style="width:21px;height:21px;">
              <div style="color:#fff;font-size: 16rpx;margin-left: 5px;">{{ll[languageId].import_meeting}}</div>
           </div>

           <a-upload 
          ref="upload"
          multiple 
          :directory="uploadDirectory"
          :openFileDialogOnClick="uploadFileOpen"
          :showUploadList="false"
          :beforeUpload="handleBeforeUpload"
          :accept="fileTypesString"
          ></a-upload>
         </div>
         </div>
         <div style="display: flex;justify-content: end;    color: #464646;font-size: 12px;height: 30px;line-height: 20px;">
          <div style="width:160px;text-align: center;" v-if="dir == '/learning/'">[&nbsp;{{ll[languageId].support_learning}}&nbsp;]</div>
          <div style="width:160px;text-align: center;" v-if="dir == '/meeting/'">[&nbsp;{{ll[languageId].support_meeting}}&nbsp;]</div>
        </div>
      </div>
     
      <div style="margin-bottom: 200px;">
        <div  style="min-width: 900px;z-index: 1000;width: calc(100% - 250px);padding: 20px 0 20px 25px; margin-left:25px;margin-right:25px;position: fixed; top:188px;left:200px;background: #F2F4F0;height: 48px;display: flex;
        align-items: center;justify-content: flex-start;font-size: 15px;color:#000;font-weight: 600;">
          <div style="width:5%;cursor: pointer" @click="selectAll" >
            <div v-if="selectedFruits.length == resData.length && resData.length > 0">
              <img  src="@/assets/pc/selected.png" alt="logo" style="width:16px;height:16px;">
            </div>
            <div v-if="selectedFruits.length>0 && selectedFruits.length <resData.length">
              <img  src="@/assets/pc/selected_1.svg" alt="logo" style="width:16px;height:16px;">
            </div>
            <div v-if="selectedFruits.length==0">
              <img  src="@/assets/pc/unselect.png" alt="logo" style="width:16px;height:16px;">
            </div>
          </div>
          <div style="width:35%;">{{ll[languageId].name}}</div>
          <!-- <div style="width:15%;">{{ll[languageId].type}}</div> -->
          <div style="width:20%;">{{ll[languageId].size}}</div>
          <div style="width:20%;">{{ll[languageId].time}}</div>
          <div style="width:20%;text-align: center;">{{ll[languageId].operation}}</div>
        </div>
         
        <a-spin :spinning="loading" class="loading-spinner">
            <div  class="content_content" style="margin-top:122px;font-size: 14px;color:#3A3A3A;font-weight: 600;display: flex;flex-direction: column;align-items: center;" >
                <div style="padding-top: 10px;padding-bottom: 10px;padding-left: 25px;cursor: pointer;;height: 111px;width: 100%;display: flex;align-items: center;justify-content: flex-start;color: #3A3A3A;font-size: 14px;"
                v-for="(item) in resData" :key="item.key" :style="isSelected(item.id) ? 'background: #F9FAF9;':'background: #fff;'" @click="updateSelection(item.id)">
                  <div v-if="isSelected(item.id)" style="width:5%;cursor: pointer" >
                    <img  src="@/assets/pc/selected.png" alt="logo" style="width:16px;height:16px;">
                  </div>
                  <div v-else style="width:5%;cursor: pointer" >
                    <img  src="@/assets/pc/unselect.png" alt="logo" style="width:16px;height:16px;">
                  </div>

                  <div style="width:35%; display: flex;align-items: center;" >
                      <div v-if="item.type==1" style="border: 0.4px solid #b8b5b5;border-radius: 4px;overflow: hidden;"><img  src="@/assets/pc/folder-ic.png" alt="logo" style="width:60px;height:80px;cursor: pointer;" @click="goNextDir(item)"></div>
                      
                      <div v-if="item.type==2 && isImageFile(item.path)" style="border: 0.4px solid #b8b5b5;border-radius: 4px;overflow: hidden;width: 60px;;"><img  :src="item.path" alt="logo" style="width:60px;height:80px;"></div>
                      <div v-if="item.type==2 && !isImageFile(item.path)" style="border: 0.4px solid #b8b5b5;border-radius: 4px;overflow: hidden;width: 60px;"><img  :src="getImageSrc(item.path)" alt="logo" style="width:60px;height:80px;"></div>
                      
                      
                      <div v-if="item.type==1" style="margin-left: 15px;cursor: pointer;font-weight: bold;flex:1;" @click="goNextDir(item)">{{removePrefix(item.name,dir)}}</div>
                      <div v-if="item.type!=1" style="margin-left: 15px;font-weight: bold;  flex:1;  text-overflow: ellipsis;white-space: nowrap;overflow: hidden;padding-right: 20px;" :title="item.name">{{item.name}}</div>
                  </div>
                  <!-- <div v-if="item.type==1" style="width:15%;font-weight: 400;">{{ll[languageId].folder}}</div>
                  <div v-if="item.type==2" style="width:15%;font-weight: 400;">{{ll[languageId].file}}</div> -->
                  <!-- <div style="width:15%;font-weight: 400;display: flex;flex-direction: row;">
                      <div v-if="item.format.length==1">{{item.format[0]}}</div>
                      <div v-if="item.format.length>1">{{item.format.join("/")}}</div>
                  </div> -->
                  <div style="width:20%;font-weight: 400;">{{ item.size }}</div>
                  <div style="width:20%;font-weight: 400;">{{ convertToLocalTime(item.updateAt)}}</div>
                  <div style="width:20%;display: flex;justify-content: center;">
                    <div v-if="item.type==1"  style="width:27px;height:26px;"></div>
                    <div @click.stop="handleDownload(item)" style="display: flex;justify-content: center;align-items: center;flex-direction: column;" :style="item.type==1?'margin-left:25px;':''">
                      <div >
                        <img  src="@/assets/pc/download.png" alt="logo" style="width:20px;height:20px;cursor: pointer;" >
                      </div>
                      <div style="margin-top:5px;color: #000;font-weight: 500;font-size: 11px;">{{ll[languageId].download}}</div>
                    </div>
                    <div @click.stop="openShare(item)" v-if="item.type==2" style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-left:25px;">
                      <div>
                        <img   src="@/assets/pc/share.png" alt="logo" style="width:20px;height:20px;cursor: pointer;">
                      </div>
                      <div style="margin-top:5px;color: #000;font-weight: 500;font-size: 11px;">{{ll[languageId].share}}</div>
                    </div>
                    <div @click.stop="openRemoveModal(item.id)" style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-left:25px;">
                      <div>
                        <img  src="@/assets/pc/delete.png" alt="logo" style="width:20px;height:20px;cursor: pointer;">
                      </div>
                      <div style="margin-top:5px;color: #000;font-weight: 500;font-size: 11px;">{{ll[languageId].delete}}</div>
                    </div>
                    <div v-if="dir !='/other/'" @click.stop="showDeviceListModal(item.id)" style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-left:25px;">
                      <div>
                        <img  src="@/assets/pc/send.png" alt="logo" style="width:20px;height:20px;cursor: pointer;">
                      </div>
                      <div style="margin-top:5px;color: #000;font-weight: 500;font-size: 11px;">{{ll[languageId].sync}}</div>
                    </div>
                  </div>
                </div>

                <div v-if="resData.length==0 && loading==false" style="margin-top: 150px;align-items: center;display: flex;flex-direction: column;justify-content: center;">
                   <div style="width:101px;height: 101px;"><img  src="@/assets/pc/noList.png" alt="logo" style="width:101px;"></div>
                  <div style="font-size: 12px;font-weight: 500;margin-top:15px;">{{ll[languageId].no_data}}</div>
                </div>
          </div>
        </a-spin>

        <Pagination  style="display: none"
            v-model="pagination.current"
            :total="pagination.totalCount"
            show-size-changer
            :page-size="pagination.pageSize"
            @onShowSizeChange="onShowSizeChange"
            ></Pagination>
      </div>
    
      <a-table  style="display: none"
      :columns="columns" 
      :data-source="resData.items"
      :pagination="false"
      :rowKey="(record,index) => index"
      :row-selection="{selectedRowKeys:selectedRowKeys,onChange:onSelectChange}"
      :customRow="customRowFunc"
      >
        <template slot="name" slot-scope="text, record,index">
          <a-icon type="folder" v-if="record.isDir"/>
          <a-icon type="file" v-else/>
          &nbsp;&nbsp;
          <template v-if="addFolder && index === 0">
              <a-input v-model="addFolderName" ref="addFolderInputRef" style="width:50%" @keyup.enter="handleAddFolderOK"/>&nbsp;
              <a-button icon="check" type="primary" size="small" @click="handleAddFolderOK"/>&nbsp;
              <a-button icon="close" type="primary" size="small" @click="handleAddFolderCancle"/>
          </template>
          <template v-else-if="renameIndex === index">
              <a-input v-model="renameValue" ref="renameInputRef" style="width:50%" @keyup.enter="handleRenameOK"/>&nbsp;
              <a-button icon="check" type="primary" size="small" @click="handleRenameOK"/>&nbsp;
              <a-button icon="close" type="primary" size="small" @click="handleRenameCancle"/>
          </template>
          <template v-else>
            <a v-if="record.isDir" href="javascript:;" @click="gonext(text)">{{text}}</a>
            <span v-else>{{text}}</span>
           </template>
        </template>
      </a-table>
    </div>



  
  <!-- 移动对话框 -->
  <a-modal 
  v-model="dirModalVisible" 
  :title="dirModalTitle" 
  width="700px"
  centered
  :ok-text="dirModalOkText" 
  cancel-text="取消" 
  @ok="handleMvcp">
    <div class="path">
      <template v-if="dirModalNavs.length === 1">
        全部文件
      </template>
      <template v-else>
        <a href="javascript:;" @click="mvcpGoback()">返回上一级</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="mvcpGoto(0)">全部文件</a>
        <template v-for="(v,i) of dirModalNavs">
          <template v-if="i !== 0">
            <template v-if="i === dirModalNavs.length-1">
              &nbsp;>&nbsp; {{v}}
            </template>
            <template v-else>
              &nbsp;>&nbsp; <a href="javascript:;" @click="mvcpGoto(i)" :key=i>{{v}}</a>
            </template>
          </template>
        </template>
      </template>
    </div>
    <div style="height:300px;overflow-y:auto">
      <a-table 
      :columns="dirModalColumns" 
      :data-source="dirModalData"
      :pagination="false"
      :showHeader="false"
      :rowKey="(record,index) => index"
      >
      <template slot="name" slot-scope="text">
        <a-icon type="folder" />
        &nbsp;&nbsp;
          <a  href="javascript:;" @click="mvcpGonext(text)">{{text}}</a>
      </template>
      </a-table>
    </div>
  </a-modal>

  <!-- 删除对话框 -->
  <a-modal 
  v-model="removeModalVisible" 
  width="450px"
  centered
  :style="{ fontSize: '14px', color: '#1E202B', fontWeight:'600' }"
  @ok="handleRemove"
    @cancel="removeModalVisible = false"
    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  >
  <template #title>
      <span style="font-size: 14px;color:#000;font-weight: bold;">{{ll[languageId].confirm_delete}}</span>
    </template>
    <!-- <p style="text-align: center"><a-icon type="exclamation-circle" theme="twoTone" style="font-size:46px"/></p> -->
    <p style="text-align: center">{{ll[languageId].confirm_delete_file}}</p>
  </a-modal>

  

  <a-modal
      v-model="showDeviceList"
      wrapClassName="vertical-center-modal"
      :style="{ fontSize: '14px', color: '#1E202B', fontWeight:'600' }"
      width="475px"

      @ok="hideDeviceListModal"
      :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
    >
    <template #title>
      <span style="font-size: 14px;color:#000;font-weight: bold;">{{ll[languageId].choose_device}}</span>
    </template>
      <div  v-for="item in deviceList" :key="item.id"  style="    align-items: center;display: flex;justify-content: space-between;width: 394px;height: 94px;border-radius: 2px;border: 1px solid #BFBFBF;margin-bottom: 20px;">
        <div style="    margin: 15px;display: flex;align-items: center;">
          <div>
            <img  src="@/assets/pc/device_i.png" alt="logo" style="width:49px;height:61px;">
          </div>
          <div style="margin-left:15px">
            <div style="    display: flex;">
              <div style="font-weight: 600;font-size: 14px;color: #1E202B;">{{ item.modelName}}</div>
            <div style="margin-left:20px;font-weight: 600;font-size: 14px;color: #1E202B;" v-if="getMachineNumbers()==item.machineNumber">{{ll[languageId].current_device_tips}}</div>
            </div>
            <div style="font-weight: 400;font-size: 10px;color: #A1A1A1;">{{ll[languageId].device_number}}：{{ item.machineNumber}}</div>
          </div>
        </div>
        <div style="margin-right: 15px;">
              <div v-if="selectedmachineNumber == item.machineNumber" style="width:5%;cursor: pointer" @click="selectedmachineNumber=item.machineNumber">
                <img  src="@/assets/pc/r-select.png" alt="logo" style="width:20px;height:20px;">
              </div>
              <div v-else style="width:5%;cursor: pointer" @click="selectedmachineNumber=item.machineNumber">
                <img  src="@/assets/pc/r-unselect.png" alt="logo" style="width:20px;height:20px;">
              </div>


        </div>
      </div>
    </a-modal>


    <a-modal
      v-model="showPaperImport"
      wrapClassName="vertical-center-modal"
      :style="{ fontSize: '14px', color: '#1E202B', fontWeight:'600' }"
      width="475px"

      @ok="paperImportComfirm"
      @cancel="cancelPaperImport"
      :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
    >
    <template #title>
      <span style="font-size: 14px;color:#000;font-weight: bold;">{{ll[languageId].choose_paper_import}}</span>
    </template>
      <div  v-for="item in paperImportList[languageId]" :key="item.id" @click="selectedFormat=item.format"  :style="selectedFormat == item.format? 'border: 1px solid #000;':'border: 1px solid #BFBFBF;'" style="cursor: pointer;align-items: center;display: flex;justify-content: space-between;width: 394px;height: 94px;border-radius: 2px;margin-bottom: 20px;">
        <div style="    margin: 15px;display: flex;align-items: center;">
          <div>
            <img v-if="item.format =='.pdf'"  src="@/assets/pc/pdf.svg"  alt="logo" style="width:49px;height:61px;">
            <img v-if="item.format =='.png'"  src="@/assets/pc/template.svg"  alt="logo" style="width:49px;height:61px;">
          </div>
          <div style="margin-left:15px">
            <div style="    display: flex;">
              <div style="font-weight: 600;font-size: 14px;color: #1E202B;">{{ item.title}}</div>
            </div>
            <div style="font-weight: 400;font-size: 10px;color: #A1A1A1;">{{ item.smallTitle}}</div>
          </div>
        </div>
        <div style="margin-right: 15px;">
              <div v-if="selectedFormat == item.format" style="width:5%;" >
                <img  src="@/assets/pc/r-select.png" alt="logo" style="width:20px;height:20px;">
              </div>
              <div v-else style="width:5%;" >
                <img  src="@/assets/pc/r-unselect.png" alt="logo" style="width:20px;height:20px;">
              </div>


        </div>
      </div>
    </a-modal>


      <!-- 上传列表 -->
  <div 
  id="upload_div"
  v-show="showTransfer">
    <div style="height: 42px;border-bottom: 2px solid #f6f6f6;background-color: #275B52;color: #fff;padding: 0 25px ;">
      <a-row justify="space-between" type="flex" style="line-height:40px">
        <a-col style="font-size:14px">{{ll[languageId].send_to}} {{deviceName}} ({{this.uploadFinallyCount()}}/{{ Object.keys(this.uploadList).length }})</a-col>
        <a-col style="">
       
        <a-icon v-if="this.uploadFinallyCount() == Object.keys(this.uploadList).length"  type="delete" @click="this.clearList" />
        &nbsp;&nbsp;
        <a-icon v-if="this.showTransferUploadList" type="minus" @click="()=>{ this.showTransferUploadList = false}"/>
        <a-icon v-else type="border" @click="()=>{ this.showTransferUploadList = true}"/>
        &nbsp;&nbsp;<a-icon type="close" @click="()=>{ this.showTransfer = false;}"/>
        </a-col>
      </a-row>
    </div>
    <div 
    style="height:338px;overflow:auto;margin-top:5px;margin-left: 25px;margin-right: 25px;"
    v-show="showTransferUploadList">
      <template v-for="v of this.uploadList">
        <a-row :key="v.key" class="upload_div_row">
          <a-col  style="width: 40%;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;color:#2A2C2F;font-size: 14px;" :title="v.filename">{{ v.filename }}</a-col>
          <a-col  style="width: 20%;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;color:#2A2C2F;font-size: 14px;text-align: center;">{{ formatBytes(v.total) }}</a-col>
       

          <a-col style="width: 40%;display: flex;align-items: center;justify-content: space-between">
            <div v-if = "v.status === 'success'" style="width:100%">
              <a-progress :percent="v.percent" :status="v.status"  strokeColor="#275B52"/>  
            </div>
            <div v-if = "v.status === ''" style="width:100%">
              <a-progress :percent="v.percent"   strokeColor="#275B52"/>  
            </div>
            <div v-if = "v.status === 'normal'" style="width:80%">
              <a-progress :percent="v.percent" :status="v.status"  strokeColor="#f5222d" :show-info="true"/>  
            </div>
            <a v-if="v.status === 'normal'" style="color: #f5222d;" @click="deleteItem(v)">{{ll[languageId].del}}</a>
          </a-col>
        </a-row>
      </template>
    </div>
  </div>

    <div id="download_div" v-show="showDownloadList">
  <div style="height: 42px;border-bottom: 2px solid #f6f6f6;background-color: #275B52;color: #fff;padding: 0 25px ;">
    <a-row justify="space-between" type="flex" style="line-height: 40px;">
      <a-col style="font-size: 14px;">{{ll[languageId].download_list}} ({{successCount}}/{{ this.downloadProgressList.length }})</a-col>
      <a-col style="">
        <a-icon v-if="successCount == this.downloadProgressList.length"  type="delete" @click="this.clearDownloadList" />
        &nbsp;&nbsp;
        <a-icon v-if="this.showDownloadProgressList" type="minus" @click="toggleDownloadList" />
        <a-icon v-else type="border" @click="toggleDownloadList" />
        &nbsp;&nbsp;<a-icon type="close" @click="closeDownloadList" />
      </a-col>
    </a-row>
  </div>
  <div style="height:338px;overflow:auto;;margin-top:5px;margin-left: 25px;margin-right: 25px;" v-show="showDownloadProgressList">
    <div v-for="(progress, index) in downloadProgressList" :key="progress.id">
      <!-- <a-row class="download_div_row">
        <a-col :span="6" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
          {{ progress.filename }}
        </a-col>
        <a-col :span="14">
          <a-progress :percent="progress.percent" :status="progress.status" />
        </a-col>
        <a-col :span="4">
          <a v-if="progress.status === 'exception'" @click="deleteDownload(index)">删除</a>
        </a-col>
      </a-row> -->

      <a-row  class="download_div_row">
          <a-col  style="width: 40%;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;color:#2A2C2F;font-size: 14px;" :title="progress.filename">{{ progress.filename }}</a-col>
          <a-col  style="width: 20%;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;color:#2A2C2F;font-size: 14px;text-align: center;">{{ formatBytes(progress.size) }}</a-col>
          <a-col style="width: 40%;display: flex;align-items: center;justify-content: space-between">
            <div :style="progress.status === 'exception'?'width:75%':'width:100%'">
              <a-progress :percent="progress.percent" :status="progress.status"  strokeColor="#275B52"/>  
            </div>
            <a v-if="progress.status === 'exception'" @click="deleteDownload(index)">{{ll[languageId].del}}</a>
          </a-col>
        </a-row>
    </div>
  </div>
</div>
    

 <!-- 添加二维码弹窗 -->
 <a-modal
    v-model="qrCodeModalVisible"
    height="628px"
  width="433px"
  @cancel="shareModalVisible=false;selectedShareFormat=''"

      centered
      :style="{ fontSize: '14px', color: '#1E202B', fontWeight:'600' }"
      footer=""

  >
  <template #title>
      <span style="font-size: 14px;color:#000;font-weight: bold;">{{ll[languageId].share}}：{{qrCodeName}}</span>
    </template>
    <div style="text-align: center;">
      <p>{{ll[languageId].scan}}</p>
      <p><canvas id="qrcode" ref="qrcode" class="canvas"></canvas></p>
      <p><a-button type="primary" style="background-color: #275B52;color: #fff;border-color: #275B52;width: 111px;" @click="downloadQr(qrCodeName)">{{ll[languageId].download_code}}</a-button></p>
      <div class="weixinurl">{{qrCodeUrl}}</div>
      <p><a-button type="primary" style="background-color: #275B52;color: #fff;border-color: #275B52;width: 111px;" @click="copyToClipboard(qrCodeUrl)">{{ll[languageId].copy_link}}</a-button></p>
      <p style="font-size: 12px;color:#000;font-weight: 500;margin-top: 60px;display: none;">{{ll[languageId].share_tips}}</p>

    </div>
  </a-modal>

  <a-modal 
    v-model="shareModalVisible" 
    width="475px"
    height="280px"
    :style="{ fontSize: '14px', color: '#1E202B', fontWeight:'600',ZIndex:'2000' }"
    centered
    @ok="confirmShare"
    @cancel="cancelShareFormat"
    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  >
    <template #title>
      <span style="font-size: 14px;color:#000;font-weight: bold;">{{ll[languageId].choose_format}}</span>
    </template>
    <a-radio-group v-model="selectedShareFormat" class="radio-group">
      <a-radio  class="radio-item"
        v-for="(format, key) in availableFormats" 
        :key="key" 
        :value="key"
      >
        {{ format }}
      </a-radio>
    </a-radio-group>
  </a-modal>

  <a-modal 
    v-model="downloadModalVisible" 
    width="475px"
    height="280px"
    :style="{ fontSize: '14px', color: '#1E202B', fontWeight:'600' }"
    centered
    @ok="confirmDownloadFormat"
    @cancel="cancelDownloadFormat"
    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  >
    <template #title>
      <span style="font-size: 14px;color:#000;font-weight: bold;">{{ll[languageId].choose_format}}</span>
    </template>
    <a-radio-group v-model="selectedDownloadFormat" class="radio-group">
      <a-radio  class="radio-item"
        v-for="(format, key) in availableFormats" 
        :key="key" 
        :value="key"
      >
        {{ format }}
      </a-radio>
    </a-radio-group>
  </a-modal>

 </div>
</template>
<script>

import {mkdir,
list,
uploadCheck,
uploadFile,
fileRemove,
rename,
getFileTypeList,
downloadFile,
getFileExt,
sharedCreate,
mvcp} from "@/api/api"
import SparkMD5 from "spark-md5";
import  storage from 'store'
import {makeSharedLink} from "@/utils/common"
import { refreshLogin,getDeviceList,sendFiled,getTimeId,updateFileCacheTime,completeUploadFile,initUploadFile,abortUploadFile} from '@/api/api'
import { setToken,removeToken,setMachineModel,getMachineModel,setMachineNumber,getMachineNumber,setLanguage,getToken,getLanguage,getDir,setDir,getCurrentDir,setCurrentDir} from '@/api/auth'
import Pagination from "@/components/Pagination";  //1. 引入 Pagination.vue 组件，注意路径哦～
import QRCode from 'qrcode'
import { nextTick } from 'vue'

export default {
  name: 'vitransfer',
  components: {
    Pagination
  },
  data () {
    return {
      columns:[
        {title:'文件名',dataIndex: 'filename',width:'55%',scopedSlots: { customRender: 'name' }},
        {title:'大小',dataIndex: 'size',width:'20%',},
        {title:'修改时间',dataIndex: 'date'},
      ],
      selectedRowKeys:[],
      selectedNames:[],

      root:"cloud",
      navs :[],
      resData:[],

      // 删除
      removeModalVisible: false,

      // 新建目录
      addFolder: false,
      addFolderName:"",

      // 上传文件
      uploadDirectory:false,
      uploadFileOpen:false,

      // 重命名
      renameIndex:-1,
      renameValue:"",

      // 移动、拷贝
      dirModalVisible:false,
      dirModalTitle:"",
      dirModalOkText:"",
      dirModalNavs:[],
      dirModalColumns:[
        {title:'文件名',dataIndex: 'filename',scopedSlots: { customRender: 'name' }},
      ],
      dirModalData:[],
      mvcpMove:false,
      mvcpSource:[],
      mvcpTarget:"",
      
      // 传输列表
      showTransfer:false,
      showTransferUploadList:true,
      uploadList:{},

      // 分享
      shareModalVisible:false,
      shareTitle:"",
      shareCreate:true,
      shareTime:1,
      sharedRoute:"",
      sharedToken:"",
      sharedDeadline:0,
      sharedCopyText:"",
      sharedCopied:false,

      deviceList:[],
      paperImportList:{
        "zh":[
          {"img":"pdf.svg","format":".pdf","title":"PDF文档","smallTitle":"支持PDF格式"},
          {"img":"template.svg","format":".png","title":"PNG 模板","smallTitle":"支持PNG格式"}
        ],
        "en":[
          {"img":"pdf.svg","format":".pdf","title":"PDF document","smallTitle":"Supports PDF Format"},
          {"img":"template.svg","format":".png","title":"PNG template","smallTitle":"Supports PNG Format"}
        ],
      },
      deviceName:'',
      deviceId:'',
      languageName:'English',
      languageId:"en",
      languageList:[
        {"id":"zh","name":"中文"},
        {"id":"en","name":"English"},
      ],
    
      ll: {
        "en":{
          "timeList":[
            {"id":"86400","name":"12 Hours","desc":"Expires 12 hours after upload"},
            {"id":"7200","name":"2 Hours","desc":"Expires 2 hours after upload"},
            {"id":"1800","name":"30 Minutes","desc":"Expires 30 minutes after upload"},
          ],
          "current_device": "current device name",
          "choose_device": "choose device",
          "choose_paper_import": "choose import paper type",
          "choose_language": "choose language",
          "exit": "Exit",
          "paper_name": "Paper",
          "meeting_name": "Meeting",
          "learning_name": "Learning",
          "picking_name": "Picking",
          "memo_name": "Memo",
          "daily_name": "Daily",
          "other_name": "Other",
          "name": "Name",
          "type": "Type",
          "size": "Size",
          "time": "DateTime",
          "operation": "Operation",
          "download": "Download",
          "delete": "Delete",
          "del": "Del",
          "import": "Import",
          "return":"Return Pre",
          "search_all":"Search all file",
          "selected":"",
          "selected_file":" files/folders selected",
          "file":"file",
          "folder":"folder",
          "no_data":"no data",

          "confirm_delete":"confirm delete",
          "confirm_delete_file":"confirm delete file？",
          "send_to":"To",
          "download_list":"download list",
          "device_number":"device number",
          "scan":"Scan the code or enter the address",
          "share":"Share",
          "download_code":"Download",
          "copy_link":"Copy Link",
          "share_tips":"Note: The shared link/QR code is valid for 12 hours",
          "choose_format":"choose format",
          "please_choose":"Please choose folder or file",
          "please_choose_format":"Please choose format",
          "format_not_exists":"The format not exists",
          "copy_success":"Copy success",
          "copy_fail":"Copy fail",
          "close_download_tips":"There are files being downloaded and the list cannot be closed.",
          "choose_send_device":"please choose sync device",
          "send_success":"Sync success",
          "send_fail":"Sync fail",
          "cancel":"Cancel",
          "ok":"Confirm",
          "send":"Sync",
          "no_device":"Please log in again on the machine to automatically add the device",
          "current_no_device":"no device",
          "current_device_tips":"current device",
          "send_loading_text":"Syncing",
          "loading_text":"Loading",
          "send_error":"sync fail",
          "try_download":"retry",
          "downloading":"downloading",
          "error":"server error",
          "import_learning":"Import Book",
          "import_meeting":"Import Template",
          "import_paper":"Import",
          "support_learning":"Support PDF、EPUB",
          "support_meeting":"Support PNG",
          "support_paper":"Support PDF",
          "outTime":"Note: To ensure the privacy and security of user data, uploaded files will be automatically cleared after %s.",
          "sync":"Sync",
          "max_size":"The file size exceeds 400MB and cannot be uploaded.",
          "file_time":"File Expires in",
        },
        "zh":{
          "timeList":[
            {"id":"86400","name":"12小时","desc":"上传后12小时内有效"},
            {"id":"7200","name":"2小时","desc":"上传后2小时内有效"},
            {"id":"1800","name":"30分钟","desc":"上传后30分钟内有效"},
          ],
          "current_device": "当前设备",
          "choose_device": "选择设备",
          "choose_paper_import": "选择导入类型",
          "choose_language": "选择语言",
          "exit": "退出",
          "paper_name": "笔记",
          "meeting_name": "会议",
          "learning_name": "学习",
          "picking_name": "随记",
          "memo_name": "便签",
          "daily_name": "日程",
          "other_name": "其他",
          "name": "名称",
          "type": "类型",
          "size": "大小",
          "time": "时间",
          "operation": "操作",
          "download": "下载",
          "delete": "删除",
          "del": "删除",
          "import": "导入",
          "return":"返回上一级",
          "search_all":"搜索全部文件",
          "selected":"已选中",
          "selected_file":" 文件/文件夹",
          "file":"文件",
          "folder":"文件夹",
          "no_data":"暂无数据",
          "confirm_delete":"确认删除",
          "confirm_delete_file":"确认删除文件吗？",
          "device_number":"序列号",
          "send_to":"发送至",
          "download_list":"下载列表",
          "scan":"扫码或输入地址",
          "share":"分享",
          "download_code":"下载二维码",
          "copy_link":"复制链接",
          "share_tips":"注：分享的链接/二维码有效期为12小时",
          "choose_format":"选择格式",
          "please_choose":"请选择文件夹或者文件",
          "please_choose_format":"请选择格式",
          "format_not_exists":"该格式的文件不存在",
          "copy_success":"复制成功",
          "copy_fail":"复制失败",
          "close_download_tips":"有正在下载的文件，不能关闭列表。",
          "choose_send_device":"请选择同步的设备",
          "send_success":"同步成功",
          "send_fail":"同步出错",
          "cancel":"取消",
          "ok":"确认",
          "send":"同步",
          "no_device":"暂无设备，请在机器上重新登录，即可自动添加设备",
          "current_no_device":"暂无设备",
          "current_device_tips":"当前设备",
          "send_loading_text":"同步中",
          "loading_text":"加载中",
          "send_error":"同步失败",
          "try_download":"重试",
          "downloading":"下载中",
          "error":"服务异常",
          "import_learning":"导入书籍",
          "import_meeting":"导入模板",
          "import_paper":"导入",
          "support_learning":"支持PDF、EPUB格式",
          "support_meeting":"支持PNG格式",
          "support_paper":"支持PDF格式",
          "outTime":"提示：为保证用户数据的隐私安全，上传的文件%s后自动清空",
          "sync":"同步",
          "max_size":"文件的大小不能超过100M",
          "file_time":"文件有效时间"
        }
      },

      fileTypeList:[
          {
            dir: "/learning/",
            num: "0",
          },
          {
          dir: "/paper/",
          num: "0",
          upload:"1",
          },
         
          {
            dir: "/meeting/",
            num: "0",
          },
         
          {
            dir: "/picking/",
            num: "0",
          },
          {
            dir: "/memo/",
            num: "0",
          },
          {
            dir: "/daily/",
            num: "0",
          },
          {
            dir: "/other/",
            num: "0",
          },
         
      ],
      fileTypeListId:"/paper/",
      preDir:"",
      dir:"/paper/",
      fileName:"",
      isFocused:false,
       pagination:{ //分页数据
        current : 1,
        pageSize: 10,
        totalCount:500
      },

      selectedFruits:[],
      sendSelectedFruits:[],
      showDeviceList:false,
      showPaperImport:false,
      selectedmachineNumber:"",
      
      showDownloadList: false,
      showDownloadProgressList: true,
      downloadProgressList: [],
      deleteId:"",

      qrCodeModalVisible: false,
      qrCodeUrl: '',
      qrCodeName:'',

  
      downloadModalVisible: false,
      selectedDownloadFormat: '',
      selectedShareFormat: '',
      availableFormats: {},
      downloadFileId: '',
      loading: true, // 新增加载状态
      fileTypesString:"",

      downLoadList:{},
      shareItem:{},

      timeName:"",
      timeId:0,
      selectedFormat:".pdf",
      headers:{},
      allEtags:{},
      uploadedSize:{},
      allUploadId:{},
      allUploadFilepath:{},
      uploadLocks:{},
    }
  },
  computed: {
     successCount() {
      let total = 0;
      for (var item of this.downloadProgressList) {
        if(item.status == 'success') {
          total += 1
        }
       
      }
      return total;
    },
  
  },
  mounted () {
    let ll = getLanguage()
    if (ll == "" || ll == undefined) {
      const selectedLanguage = this.languageList.find(item => item.id === this.languageId);
      if (selectedLanguage) {
        this.languageName = selectedLanguage.name;
        setLanguage(selectedLanguage.id)
      }
    } else {
      this.languageId = ll
      const selectedLanguage = this.languageList.find(item => item.id === this.languageId);
      if (selectedLanguage) {
        this.languageName = selectedLanguage.name;
      }
    }
    let dir = getDir()
    console.info("dirdir",dir)
    if(dir) {
      this.fileTypeListId = dir
    }

    let currentDir = getCurrentDir()
    if(currentDir) {
      this.dir = currentDir

      let preDir = this.getUpperDirectory(this.dir)
      if (preDir!="" && preDir!="/" ) {
        this.preDir = preDir
      }

    } else {
      this.dir = this.fileTypeListId
    }

  

    this.refreshLogins();
    this.getUserDeviceList();
    this.setFileTypesString();
    this.getTimeNames()
  },
 
  methods:{
     convertToLocalTime(utcString) {
    // Parse the input UTC string into a Date object
    const [datePart, timePart] = utcString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    // Convert to local time
    const localDate = new Date(utcDate.toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));

    // Format the local time
    const localYear = localDate.getFullYear();
    const localMonth = String(localDate.getMonth() + 1).padStart(2, '0');
    const localDay = String(localDate.getDate()).padStart(2, '0');
    const localHours = String(localDate.getHours()).padStart(2, '0');
    const localMinutes = String(localDate.getMinutes()).padStart(2, '0');
    const localSeconds = String(localDate.getSeconds()).padStart(2, '0');

    return `${localYear}-${localMonth}-${localDay} ${localHours}:${localMinutes}:${localSeconds}`;
},
    redirectToViwoods() {
      window.open('https://www.viwoods.com', '_blank');
    },
    clearList(){
      this.uploadList = {}
    },
    clearDownloadList(){
      this.downloadProgressList = []
    },
    removePrefix  (path, prefix) {
      if (path.startsWith(prefix)) {
        return path.slice(prefix.length).replace(/^\/|\/$/g, '');
      }
      return path.replace(/^\/|\/$/g, '');
    },
    getMachineNumbers() {
      return getMachineNumber();
    },
    getTimeNames(){
      getTimeId({}).then(ret => {
            if(ret.data.code == 200) {
              this.timeId = ret.data.data.fileCacheTime
              this.ll[this.languageId].timeList.forEach((tab) => {
                if(tab.id == this.timeId) {
                  this.timeName = tab.name
                }
              });
            } 
          }).catch(() => {
          })
    },
    
    setFileTypesString() {
      let fileTypes = [];
      if(this.dir == "/paper/") {
        fileTypes =  ['.pdf','.png'];
      }
      if(this.dir == "/meeting/") {
        fileTypes =  ['.png'];
      }
      if(this.dir == "/learning/") {
        fileTypes =  ['.pdf','.epub'];
      }
      if(this.dir == "/picking/") {
        fileTypes =  [];
      }
      if(this.dir == "/daily/") {
        fileTypes =  [];
      }
      if(this.dir == "/memo/") {
        fileTypes =  [];
      }
      return this.fileTypesString = fileTypes.join(',')
    },
    downloadQr(name) {
      const canvas = document.getElementById('qrcode');
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = name + '.png';
      link.click();
    },
     copyToClipboard(text) {
      try {
        // 创建一个临时的输入元素
        const input = document.createElement('input');
        // 将需要复制的文本赋值给临时输入元素
        input.setAttribute('value', text);
        // 将临时输入元素添加到文档中
        document.body.appendChild(input);
        // 选择输入元素中的文本
        input.select();
        // 执行复制命令
        document.execCommand('copy');
        // 移除临时输入元素
        document.body.removeChild(input);
    


        this.$message.success(this.ll[this.languageId].copy_success)
      } catch (err) {
        this.$message.error(this.ll[this.languageId].copy_fail)
      }
    },
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 B'; // 如果字节数为0，返回 "0 B"

        const k = 1024; // 定义千字节
        const dm = decimals < 0 ? 0 : decimals; // 保证小数点位数不为负数
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; // 定义单位数组

        // 计算单位的序号
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        // 计算格式化的数值并返回带单位的字符串
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
   
    generateQRCode(text) {
      const canvas = document.getElementById('qrcode')
      // const canvas = this.$refs.qrcode
      // if (!canvas) {
      //   console.error('Canvas element not found')
      //   return
      // }
      console.info("canvascanvas",canvas)
      QRCode.toCanvas(canvas, text, function (error) {
        if (error) console.error("errorerrorerror",error)
        console.log('success!')
      })
    },
    handleDownload(o) {
      this.downloadModalVisible = false;
      this.availableFormats = {}
      this.downLoadList = {}
      this.downloadFileId = ""
      this.selectedDownloadFormat = ""
      if (o == '' && this.selectedFruits.length==0) {
        return this.$message.error(this.ll[this.languageId].please_choose)
      }
      if(o) {
        this.downloadFileId = o.id;
      } 
      
      let args = {}
      if (o) {
        let ids = [];
        ids.push(o.id);
        args = { id: JSON.stringify(ids) };
      } else {
        args = { id: JSON.stringify(this.selectedFruits) };
      }
           // 开始加载动画
           const loading = this.$loading({
            lock: true,
            text: this.ll[this.languageId].loading_text,
            customClass: 'custom-loading'
          });

      getFileExt(args).then(res => {
        console.info("res.data", res.data.data);
        if (!res.data.data ) {
          return this.$message.error(this.ll[this.languageId].format_not_exists);
        }

        for (const key of res.data.data) {
          this.availableFormats[key]=key
          if(this.selectedDownloadFormat == "") {
            this.selectedDownloadFormat = key
          }
          if(key=="pdf") {
            this.selectedDownloadFormat = key
          }
          if(key=="epub") {
            this.selectedDownloadFormat = key
          }
        }

        // this.availableFormats = this.formatList[this.fileTypeListId];
        if( Object.keys(this.availableFormats).length<=1) {
          for (const format in this.availableFormats) {
            this.selectedDownloadFormat = format
          }

          this.confirmDownloadFormat()
        } else {
          this.downloadModalVisible = true;
        }
        loading.close()
      });

 
    },
    openShare(item) {
      this.shareItem = item

      if(item.format.length>1) {
        for (const key of item.format) {
          this.availableFormats[key]=key

          if(key=="pdf") {
            this.selectedShareFormat = key
          }
          if(key=="epub") {
            this.selectedShareFormat = key
          }

        }
        this.shareModalVisible = true;
      } else {
        this.confirmShare()
      }
    },
    confirmShare() {
      console.info("this.selectedShareFormat",this.selectedShareFormat)
      if(this.shareModalVisible && this.selectedShareFormat == "") {
        return this.$message.error(this.ll[this.languageId].please_choose_format)
      }
      this.qrCodeName = this.shareItem.origin[0].name
        this.qrCodeUrl = this.shareItem.origin[0].path
      if(this.selectedShareFormat != '') {

        for (let i = 0; i < this.shareItem.origin.length; i++) {
          if(this.shareItem.origin[i].path.includes(this.selectedShareFormat)) {
            this.qrCodeName = this.shareItem.origin[i].name
            this.qrCodeUrl = this.shareItem.origin[i].path
          }
        }
      }

      
        this.qrCodeModalVisible = true

        nextTick(() => {
          this.generateQRCode(this.qrCodeUrl)
        })
    },
    confirmDownloadFormat() {
      if(Object.keys(this.availableFormats).length > 1  &&this.selectedDownloadFormat == '') {
        return this.$message.error(this.ll[this.languageId].please_choose_format)
      }
      let args = {}
      if (this.downloadFileId != '') {
        let ids = [];
        ids.push(this.downloadFileId);
        args = { id: JSON.stringify(ids), format: this.selectedDownloadFormat };
      } else {
        args = { id: JSON.stringify(this.selectedFruits), format: this.selectedDownloadFormat };
      }

      const loading = this.$loading({
        lock: true,
        text: this.ll[this.languageId].loading_text,
        customClass: 'custom-loading'
      });


      downloadFile(args).then(res => {
        loading.close();
        console.info("res.data", res.data.data);
        if (!res.data.data || res.data.data.length < 1) {
          return this.$message.error(this.ll[this.languageId].format_not_exists);
        }
        for (var item of res.data.data) {
          console.info("item", item);
          this.downloadFile(item);
        }
           }).catch(() => {
             // 结束加载动画
            loading.close();
            return this.$message.error(this.ll[this.languageId].error)

          });

      this.downloadModalVisible = false;
      this.selectedDownloadFormat = '';
      if (!this.downloadFileId)  {
        this.selectedFruits = [];
      }
    },
    cancelDownloadFormat() {
      this.downloadModalVisible = false;
      this.selectedDownloadFormat = '';
      this.availableFormats = {}

    },
    cancelShareFormat() {
      this.shareModalVisible = false;
      this.selectedShareFormat = '';
      this.availableFormats = {}
      this.shareItem = {}
    },

// 其他方法保持不变
async downloadFile(file, startByte = 0,index=-1) {
  const progress = {
    hash: file.hash,
    filename: file.name,
    percent: 0,
    status: "active",
    xhr: null,
    downloadedBytes: startByte,
    size:file.size
  };
  if(index > -1) {
    this.downloadProgressList[index] = progress
  } else {
    this.downloadProgressList.push(progress);
  }
  this.showDownloadList = true;
  console.info("this.downloadProgressList",this.downloadProgressList)

  try {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", file.path, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Range", `bytes=${startByte}-`);

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        progress.downloadedBytes = startByte + event.loaded;
        progress.percent = Math.round((progress.downloadedBytes * 100) / file.size);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 206) {
        progress.status = "success";
        const blob = new Blob([xhr.response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        progress.status = "exception";
        throw new Error("Download failed");
      }
    };

    xhr.onerror = () => {
      progress.status = "exception";
      console.error("Download failed");
    };

    xhr.onabort = () => {
      progress.status = "paused";
    };

    if (file.path.includes("static/uploadfile")) {
      xhr.setRequestHeader("Authorization", "Bearer " + getToken());
    }
    progress.xhr = xhr;
    xhr.send();
  } catch (error) {
    progress.status = "exception";
    console.error("Download failed:", error);
  }
},

pauseDownload(index) {
  const progress = this.downloadProgressList[index];
  if (progress && progress.xhr) {
    progress.xhr.abort();
    progress.status = "paused";
  }
},

resumeDownload(index) {
  const progress = this.downloadProgressList[index];
  if (progress && progress.status === "paused") {
    const file = this.downLoadList.find((item) => item.id === progress.id);
    if (file) {
      this.downloadFile(file, progress.downloadedBytes);
    }
  }
},

deleteDownload1(index) {
  this.downloadProgressList.splice(index, 1);
},
deleteDownload(index) {
  this.downloadProgressList.splice(index, 1);
},
retryDownload(index) {
  const progress = this.downloadProgressList[index];
  console.info("progressprogress",progress)
  if (progress ) {
    const file = this.downLoadList.find((item) => item.hash === progress.hash);
    if (file) {
      this.downloadFile(file, 0,index);
    }
  }
},

toggleDownloadList() {
  this.showDownloadProgressList = !this.showDownloadProgressList;
},

closeDownloadList() {
  const activeDownloads = this.downloadProgressList.filter(progress => progress.status === "active");
  if (activeDownloads.length > 0) {
    return this.$message.info(this.ll[this.languageId].close_download_tips)
  } else {
    // this.downloadProgressList = [];
    this.showDownloadList = false;
  }
},
    
    showDeviceListModal(id) {
      if (id == '' && this.selectedFruits.length==0) {
        return this.$message.error(this.ll[this.languageId].please_choose)
      }
      if(id) {
        this.sendSelectedFruits.push(id)
      } else{
        this.sendSelectedFruits = this.selectedFruits

      }
      this.showDeviceList = true
    },
    cancelPaperImport(e) {
      console.info(e)
      this.selectedFormat = ".pdf"
    },
    hideDeviceListModal(e) {
      console.log(e);
      if(this.selectedmachineNumber=="") {
        return this.$message.error(this.ll[this.languageId].choose_send_device)
      }
        // 开始加载动画
        const loading = this.$loading({
          lock: true,
          text: this.ll[this.languageId].loading_text,
          customClass: 'custom-loading'
        });

      sendFiled({"id":JSON.stringify(this.sendSelectedFruits),"to":this.selectedmachineNumber}).then(ret => {
            // 结束加载动画
            setTimeout(() => {
              loading.close();
              if(ret.data.code == 200) {
              console.info("ret.data",ret.data)
              this.$message.success(this.ll[this.languageId].send_success)
              this.showDeviceList = false
              this.selectedmachineNumber = ""
              this.sendSelectedFruits  =[]
            } else {
              return this.$message.error(this.ll[this.languageId].send_error)
            }
            }, 5000);
           
          }).catch(() => {
             // 结束加载动画
            loading.close();
            return this.$message.error(this.ll[this.languageId].send_error)

          })
    },
    isImageFile(filePath) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
      const extension = filePath.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    getImageSrc(path) {
      const extension = path.split('.').pop().toLowerCase();
      try {
        return require(`@/assets/pc/${extension}-ic.png`);
      } catch (error) {
        return require(`@/assets/pc/unkown.png`);
      }
    },
    isSelected(key) {
      return this.selectedFruits.includes(key);
    },
    
    updateSelection(value){
      
      if (!this.selectedFruits.includes(value)) {
        this.selectedFruits.push(value);
        console.info("valuevalue",value,this.selectedFruits)
      } else if ( this.selectedFruits.includes(value)) {
        this.selectedFruits = this.selectedFruits.filter(fruit => fruit !== value);
      }
    },
    selectAll() {
      if(this.selectedFruits.length == 0) {
        this.resData.forEach((tab) => {
          this.selectedFruits.push(tab.id)
        });
      } else {
        this.selectedFruits = [];
      }
    },
   
    getUserDeviceList(){
      getDeviceList({"pageSize":"100"}).then(ret => {
            if(ret.data.code == 200) {
              this.deviceList = ret.data.data.list || []
              if(this.deviceList.length > 0) {
               

                if(!getMachineNumber()) {
                  this.deviceId = this.deviceList[0].machineNumber
                  this.deviceName = this.deviceList[0].modelName
                  setMachineModel(this.deviceList[0].modelName)
                  setMachineNumber(this.deviceList[0].machineNumber)
                } else {
                  this.deviceId = getMachineNumber()
                  this.deviceName = getMachineModel()
                }

                this.getUserFileTypeList()
              
              } else {
                setTimeout(() => {
                  this.loading=false
                }, 500);
                this.$message.info({
                  content: this.ll[this.languageId].no_device,
                  duration: 5, 
                }
                )
              }
            } 
          }).catch(() => {
          })
    },

    getUserFileTypeList(){
      getFileTypeList({}).then(ret => {
                if(ret.data.code == 200) {
                  console.info("ret.data",ret.data)
                  this.fileTypeList = ret.data.data
                  this.goto();
                }
              }).catch(() => {
              })
    },

  changeId(item){
    //支持上传文件格式
    this.fileTypeListId=item.dir
    this.dir = item.dir
    setDir(this.fileTypeListId)
    setCurrentDir(this.fileTypeListId)
    this.setFileTypesString();
    this.preDir=""
    this.goto()
    console.info("typetype",this.fileTypeListId)
  },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    },
    refreshLogins(){
      refreshLogin({"uri":""}).then(ret => {
            if(ret.data.code == 200) {
              setToken(ret.data.data.token)
            } else {
              removeToken()
              setTimeout(() => {
                this.loadingLogin = false
                this.$router.push( { path: '/login'  })
              }, 500)
            }
          }).catch(() => {
          })
    },
    onSelectChange (selectedRowKeys, selectedRows)  {
      //console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys
      let names = [];
      for (let v of selectedRows){
        names.push(v.filename)
      }
      this.selectedNames = names;
    },
    customRowFunc(record,index){
      return {
        props: {},
        on: { // 事件
          click: () => {
            //console.log(event);
            if ((this.addFolder && index === 0) || (this.renameIndex !== -1 && this.renameIndex === index)){
              // 阻止事件 添加的目录和正在重命名的目录
              return
            }
            let selectedIdx = this.selectedRowKeys.indexOf(index)
            if ( selectedIdx === -1 ){
              this.selectedRowKeys.push(index)
              this.selectedNames.push(record.filename)
            }else{
              this.selectedRowKeys.splice(selectedIdx,1)
              this.selectedNames.splice(selectedIdx,1)
            }
            //console.log(selectedIdx ,this.selectedRowKeys );
            
          },       // 点击行
          dblclick: () => {},
          contextmenu: () => {},
          mouseenter: () => {},  // 鼠标移入行
          mouseleave: () => {}
        },

      };
    },
    goPreDir(){
      if (this.preDir=="" || this.preDir=="/" ) {
        this.preDir = ""
        return
      }
      let nowDir = this.getUpperDirectory(this.dir)
      if(!nowDir) {
        return;
      }
      this.dir = this.preDir
      setCurrentDir(this.dir)
      let preDir = this.getUpperDirectory(this.dir)
      this.preDir = preDir
      console.info("this.preDir11",this.preDir)
      if (this.preDir=="" || this.preDir=="/" ) {
        this.preDir = ""
      }
      console.info("this.preDir",this.preDir)
      this.goto()
    },
    goNextDir(item){
      if(item.type==2) {
        return
      }
      this.preDir = this.dir
      this.dir = item.id
      setCurrentDir(this.dir)
      console.info("ddddd",this.dir)
      this.goto()
    },
     getUpperDirectory(path) {
        // 确保路径以斜杠开头和结尾
        if (!path.startsWith('/') || !path.endsWith('/')) {
            console.error("路径格式错误，应以斜杠开头和结尾。");
            return null;
        }

        // 移除末尾的斜杠（如果路径长度大于1，即不是根目录）
        if (path.length > 1) {
            path = path.slice(0, -1);
        }

        // 找到上一级目录的结尾位置
        const lastSlashIndex = path.lastIndexOf('/');

        // 如果是顶级目录（如`/aa/`），则返回null
        if (lastSlashIndex <= 0) {
            return null;
        }

        // 获取上一级目录
        const upperDirectory = path.substring(0, lastSlashIndex + 1);

        return upperDirectory;
    },
    getList1(){
      list({
        folder:this.dir,
        name:this.fileName,
        pageNo:this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(res => {
      console.info("resres",res)
        this.addFolder = false;
        this.addFolderName = "";
        this.selectedRowKeys = []
        this.selectedNames = []
        this.selectedFruits = []
        if(res.data) {
          this.resData = res.data.data || [];
        } else {
          this.resData = []
        }
        console.info("resres",this.resData)
      })
    },
    onShowSizeChange(current, pageSize) {
        console.log(current, pageSize);
        this.pagination.current = current
        this.pagination.pageSize = pageSize;
        //改变完 重新渲染数据
        // this.getList()
      },

    goback(){
      if (this.navs.length > 1) {
        this.navs.pop()
        this.goto()
      }
    },
    gonext(dir){
      if (dir !== ""){
        this.navs.push(dir)
        const path = this.navs.join("/")
        this.goto(path)
      }
    },
    goto() {
    this.loading = true; // 开始加载
    const promise = this.getList();
    if (promise && typeof promise.finally === 'function') {
      promise.finally(() => {
        setTimeout(() => {
          this.loading = false; // 加载完成
        }, 300);
      });
    } else {
      // 如果 this.getList() 不是一个 Promise 或者不支持 finally 方法
      promise.then(() => {
        setTimeout(() => {
          this.loading = false; // 加载完成
        }, 300);
      }).catch(() => {
        setTimeout(() => {
          this.loading = false; // 加载完成
        }, 300);
      });
    }
  },
  async getList() {
    try {
      const response = await list({
        folder: this.dir,
        name: this.fileName,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
      console.info("resres", response);
      this.addFolder = false;
      this.addFolderName = "";
      this.selectedRowKeys = [];
      this.selectedNames = [];
      this.selectedFruits = [];
      this.resData = response.data.data || [];
      console.info("resres", this.resData);
    } catch (error) {
      console.error("Error fetching list:", error);
    }
  },
    progressColor (percent) {
      if (percent >= 80) {
        return 'red'
      } else if (percent >= 50) {
        return '#EAC100'
      }
    },
    openAddFolder(){
      if (!this.addFolder){
        const newRow = {
          filename:"",
          isDir:true,
          date:"",
          size:"-",
        }
        this.addFolder = true
        this.addFolderName = ""
        this.resData.items = [newRow,...this.resData.items]
        this.$nextTick(()=>{
          this.$refs.addFolderInputRef.focus()
        })
      }
    },
    handleAddFolderOK(){
      if (this.addFolder && this.addFolderName != ""){
        const path = this.navs.join("/") + "/" + this.addFolderName
        const args = { path:path}
        mkdir(args).then(() =>{
          const path = this.navs.join("/")
          this.goto(path)
        })
      }
    },
    handleAddFolderCancle(){
      if (this.addFolder){
        this.addFolder = false
        this.addFolderName = ""
        this.resData.items = this.resData.items.slice(1)
      }
    },
    handleUploadType(item){
      if (item.key === "folder"){
        this.uploadDirectory = true
      }else{
        this.uploadDirectory = false
      }
      this.uploadFileOpen = true
      this.$nextTick(() =>{
        this.$refs.upload.$refs.uploadRef.$el.firstChild.click();
        this.uploadFileOpen = false
      })
    },
    handleUploadType1(){
      //判断是否有下载中的操作
      for (var item of this.downloadProgressList) {
        if(item.status != 'success') {
          return this.$message.error("请等待下载完再导入")
        }
      }
      this.showDownloadList = false;
      this.uploadDirectory = false

      this.uploadFileOpen = true
      this.$nextTick(() =>{
        this.$refs.upload.$refs.uploadRef.$el.firstChild.click();
        this.uploadFileOpen = false
      })
    },
    handleUploadType2(){
      //判断是否有下载中的操作
      for (var item of this.downloadProgressList) {
        if(item.status != 'success') {
          return this.$message.error("请等待下载完再导入")
        }
      }
      this.showDownloadList = false;
      this.uploadDirectory = false

      this.showPaperImport = true
      // this.$nextTick(() =>{
      //   this.$refs.upload.$refs.uploadRef.$el.firstChild.click();
      //   this.uploadFileOpen = false
      // })
    },

    paperImportComfirm(){
      //判断是否有下载中的操作
      if(this.selectedFormat == "") {
        return this.$message.error(this.ll[this.languageId].choose_paper_import)
      }
      this.fileTypesString =  this.selectedFormat
      console.info(" this.fileTypesString", this.fileTypesString)
      this.showPaperImport = false;
            this.selectedFormat = ".pdf";

      this.$nextTick(() =>{
        this.$refs.upload.$refs.uploadRef.$el.firstChild.click();
        this.uploadFileOpen = false
        this.selectedFormat = ".pdf";

      })
    },
    handleDevice(event) {
      this.deviceId = event.key;
      for (let i = 0; i < this.deviceList.length; i++) { // 遍历行
        if(this.deviceList[i].machineNumber == this.deviceId) {
          this.deviceName = this.deviceList[i].modelName;
          setMachineModel(this.deviceName)
          setMachineNumber(this.deviceList[i].machineNumber)
          this.getUserFileTypeList()
        }
      }
    },
    handleTime(event) {
      this.timeId = event.key;
      for (let i = 0; i < this.ll[this.languageId].timeList.length; i++) { // 遍历行
        if(this.ll[this.languageId].timeList[i].id == this.timeId) {
          this.timeName = this.ll[this.languageId].timeList[i].name;
          this.timeId = this.ll[this.languageId].timeList[i].id
          updateFileCacheTime({time:this.ll[this.languageId].timeList[i].id})
        }
      }
    },
    handleLanguage(event) {

      this.languageId = event.key;
      console.info("this.languageId",this.languageId)
      const selectedLanguage = this.languageList.find(item => item.id == this.languageId);
      if (selectedLanguage) {
        this.languageName = selectedLanguage.name;
        setLanguage(selectedLanguage.id)
        console.info("getLanguage()",getLanguage())
      }

      this.ll[this.languageId].timeList.forEach((tab) => {
          if(tab.id == this.timeId) {
            this.timeName = tab.name
          }
        });
    },


    handleBeforeUpload1(file){
      // console.log(file);
      // check

      const filename = file.name
      let path = this.navs.join("/")
      if (file.webkitRelativePath !== ""){
        const lastIndex = file.webkitRelativePath.lastIndexOf("/")
        if ( lastIndex > 0){
          path += "/" + file.webkitRelativePath.slice(0,lastIndex)
        }
      }

      // 分片大小 4M
      const sliceSize = 4 * 1024 *1024
      const size = file.size
      const total = Math.ceil(size / sliceSize)

      // md5
      this.getFileMd5(file,(fileMd5) => {
        const args = {path:path,filename:filename,md5:fileMd5,size:size,sliceTotal:total,sliceSize:sliceSize}
        uploadCheck(args).then(ret =>{
          this.showTransfer = true;
          this.showTransferUploadList = true;
          this.uploadList[filename] = {
            key:fileMd5,
            filename:filename,
            total:size,
            upSize:0
          }
          //console.log(ret);
          // 开始上传
          if (ret.need) {
            const token = ret.token
            for (let current = 0;current < total ;current++){
              const index = current.toString()
              if (ret.existSlice === null || !(index in ret.existSlice)){
                let start = sliceSize * current,
                    end = start + sliceSize > file.size ? file.size : start + sliceSize;
                const blob = file.slice(start,end)

                let fd = new FormData();
                fd.append('path',path);
                fd.append('file', blob);
                fd.append('filename', filename);
                fd.append('current', index);
                fd.append('token', token);

                uploadFile(fd).then(() => {
                  // console.log(index,"ok");
                  this.updateProgress(filename, end-start)
                })
              }else{
                this.updateProgress(filename,sliceSize)
              }
            }
          }else{
            this.updateProgress(filename,size)
          }
        })

      })
      //console.log(filename,path,size,total);
      return false
    },

    deleteItem(item){
          // 根据 item.name 删除 uploadList 中对应的元素
          console.log("删除前列表:", this.uploadList);
          delete this.uploadList[item.filename]
          console.log("删除后列表:", this.uploadList);
          this.$forceUpdate();
    },
    addFile(file) {
      const originalName = file.name; // 从 file 中获取文件名
      let newFilename = originalName; // 默认 filename 是原始文件名
      let count = 1; // 序号计数

      // 检查 this.uploadList 中是否已经存在相同的 originname
      const existingNames = Object.values(this.uploadList).map(item => item.originname);

      while (existingNames.includes(newFilename)) {
        // 如果存在相同的 originname，则添加序号
        const extensionIndex = originalName.lastIndexOf("."); // 找到扩展名的位置
        if (extensionIndex !== -1) {
          // 文件名有扩展名的情况
          const baseName = originalName.substring(0, extensionIndex);
          const extension = originalName.substring(extensionIndex);
          newFilename = `${baseName}(${++count})${extension}`;
        } else {
          // 文件名没有扩展名的情况
          newFilename = `${originalName}(${++count})`;
        }
      }

      // 将文件添加到 this.uploadList 中
      this.uploadList[newFilename] = {
        key: newFilename,
        filename: newFilename, // 更新后的文件名
        originname: originalName, // 原始文件名
        total: file.size,
        upSize: 0,
        percent: 0,
        hash: "",
        status: "",
      };
      return newFilename
    },
    newupdateProgress(key,total,now) {
      const upInfo = this.uploadList[key]

      this.uploadList[key].percent = Math.round((now * 100) / total);

      // this.uploadList[key].status = "active"
      if (upInfo.percent >= 100 || (upInfo && upInfo.upSize >= upInfo.total)){
        this.uploadList[key].status = "success"
        this.uploadList[key].percent  = 100
          console.log(upInfo);
      }
      console.info("this.uploadList",this.uploadList)
      // 页面数据强制刷新
      this.$forceUpdate();
    },
    async  handleBeforeUpload(file) {
      const getFileMd5V2=(file)=> {
        return new Promise((resolve, reject) => {
            const chunkSize = 4 * 1024 * 1024; // 分块大小 4MB
            const chunks = Math.ceil(file.size / chunkSize); // 总分块数
            let currentChunk = 0;
            const fileSpark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();

            fileReader.onload = function (e) {
            fileSpark.append(e.target.result); // 追加分块数据
            currentChunk++;

            if (currentChunk < chunks) {
                loadNextChunk(); // 加载下一块
            } else {
                const md5 = fileSpark.end();
                resolve(md5);
            }
            };
            fileReader.onerror = function (err) {
            reject(err);
            };

            function loadNextChunk() {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);
            fileReader.readAsArrayBuffer(chunk); // 读取分块数据
            }
            loadNextChunk(); // 开始加载第一个分块
        });
      }
      // Helper: Split file into chunks
      const splitFile = (file, chunkSize) => {
        const chunks = [];
        let start = 0;
        while (start < file.size) {
          const end = Math.min(start + chunkSize, file.size);
          chunks.push(file.slice(start, end));
          start = end;
        }
        return chunks;
      };

     
      const uploadChunk = (chunk, url, partNumber, onChunkProgress, file,newfileName) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            // 初始化锁
            if (!this.uploadLocks[newfileName]) {
                this.uploadLocks[newfileName] = Promise.resolve();
            }
            xhr.open("PUT", url, true);
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const uploadedBytes = event.loaded;
                    // 使用锁队列更新 uploadedSize
                    this.uploadLocks[newfileName] = this.uploadLocks[newfileName].then(() => {
                        // 确保 uploadedSize 已初始化
                        if (!this.uploadedSize) {
                            this.uploadedSize = {}; // 初始化 uploadedSize 为对象
                        }
                        if (!this.uploadedSize[newfileName]) {
                            this.uploadedSize[newfileName] = {}; // 初始化 file.name 对应的对象
                        }
                        if (!this.uploadedSize[newfileName][partNumber]) {
                            this.uploadedSize[newfileName][partNumber] = 0; // 初始化 partNumber 对应的值
                        }
                        // 更新 uploadedSize
                        this.uploadedSize[newfileName][partNumber]= uploadedBytes;
                        // 遍历统计 this.uploadedSize[file.name] 的总和
                        const totalUploaded = Object.values(this.uploadedSize[newfileName]).reduce((sum, bytes) => sum + bytes, 0);

                        // console.log(`文件 ${file.name} 已上传总大小: ${totalUploaded} 字节`);
                        // 计算总进度
                        const percent = Math.floor((totalUploaded / file.size) * 100);
                        // console.info("file.size",partNumber,uploadedBytes,event.total,totalUploaded,file.size,percent)
                        onChunkProgress(newfileName,file.size,(percent >=100 ? 0.99 * totalUploaded : totalUploaded));
                    });
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const eTag = xhr.getResponseHeader("ETag").replace(/^"|"$/g, "");
                    resolve(eTag); // 返回分块的 ETag
                } else {
                    reject(new Error(`Failed to upload file partpart[${partNumber}]: ${xhr.statusText}`));
                }
            };

            xhr.onerror = () => {
                reject(new Error(`Failed to upload file part[${partNumber}]`));
            };

            xhr.send(chunk);
        });
      };

      const newfileName = this.addFile(file)
      try {
        const maxFileSize = 400 * 1024 * 1024; // Max file size: 400MB
        if (file.size > maxFileSize) {
          return this.$message.error(this.ll[this.languageId].max_size);
        }
        // Split file into 5MB chunks
        const chunkSize = 50 * 1024 * 1024;
        const fileChunks = splitFile(file, chunkSize);

        // Initialize upload state
        this.showTransfer = true;
        this.showTransferUploadList = true;
      
        const params = {
          fileName: newfileName,
          partNum: fileChunks.length,
          type: this.dir,
        };

        const ret = await initUploadFile(params);
        if (ret.data.code !== 200) {
          throw new Error(ret.data.msg || "Failed to initialize upload.");
        }

        const initData = ret.data.data;
        if (!this.allUploadId) {
            this.allUploadId = {}; 
        }
        this.allUploadId[newfileName] = initData.uploadId;
        if (!this.allUploadFilepath) {
            this.allUploadFilepath = {}; 
        }
        this.allUploadFilepath[newfileName] = initData.filePath;
        const presignedUrls = initData.uploadPath;
        console.info("Presigned URLs:", presignedUrls, this.allUploadFilepath);
        if (!presignedUrls || presignedUrls.length === 0) {
          throw new Error("No upload parts available.");
        }
        // Upload all chunks in parallel
        const uploadPromises = presignedUrls.map(async ({ url, partNumber }, index) => {
            const chunk = fileChunks[index]; // 获取对应的分片
            try {
                const eTag = await uploadChunk(chunk, url, partNumber,this.newupdateProgress,file,newfileName); // 上传分片
                console.info(`分片 ${partNumber}`,eTag)

                if (!this.allEtags) {
                    this.allEtags = {}; 
                }

                if (!this.allEtags[newfileName]) {
                    this.allEtags[newfileName] = {};
                }

                if (!this.allEtags[newfileName][partNumber]) {
                    this.allEtags[newfileName][partNumber] = eTag; 
                }
                console.info(`分片 ${partNumber} 上传成功, ETag: ${eTag}`);
            } catch (error) {
                console.error(`分片 ${partNumber} 上传失败，正在终止上传: ${error.message}`);
                // 抛出错误以终止后续任务
                throw new Error(error.message);
            }
        });
       

        // 等待所有分片上传完成
        try {
            await Promise.all(uploadPromises);
            console.info("All parts uploaded successfully. Completing upload...", this.allEtags);
        } catch (error) {
            throw new Error(error.message);
        }
        

        // Wait for all chunks to be uploaded
        console.info("All parts uploaded successfully. Completing upload...",this.allUploadId[newfileName], this.allEtags[newfileName],"正在生成["+newfileName+"]hash");
        const hash = await getFileMd5V2(file)
        // Complete the upload
        const completeParams = {
            id: 0,
            uploadId: this.allUploadId[newfileName],
            folder: this.dir,
            source: "3",
            filePath: this.allUploadFilepath[newfileName],
            fileName: newfileName,
            hash: hash,
            eTags: this.allEtags[newfileName],
        };

        const completeResult = await completeUploadFile(completeParams);
        console.info("Upload completed response:", completeResult);
        const completeRet = completeResult.data
        if (completeRet.code == 200) {
        completeRet.data.path = this.allUploadFilepath[newfileName];
        completeRet.data.size = file.size;
        completeRet.data.hash = hash;
        completeRet.data.name = newfileName;
        this.newupdateProgress(newfileName,file.size,file.size)
        this.getUserFileTypeList();
        this.goto();
        } else {
            console.info("["+newfileName+"]Failed to complete upload.",completeRet.msg)
            throw new Error("["+newfileName+"]failed to upload.");
        }
    } catch (error) {
        console.error(`Failed to upload file: ${error.message}`);
         // 取消
        let abortParams = {
              uploadId: this.allUploadId[newfileName],
              filePath: this.allUploadFilepath[newfileName],
          };
        abortUploadFile(abortParams);
        this.uploadList[newfileName].status = "normal"
        this.$forceUpdate();
        return this.$message.error(error.message);
    }
    },

    getFileMd5(file,callback){
      let fileSpark = new SparkMD5(),
          fileReader = new FileReader();

      fileReader.readAsBinaryString(file);
      fileReader.onload = function (ev) {
        fileSpark.appendBinary(ev.target.result);
        callback(fileSpark.end());
      };
      
    },
  
    updateProgress(key,value){
      const upInfo = this.uploadList[key]
      this.uploadList[key].upSize = value

      this.uploadList[key].percent = Math.round((value * 100) / upInfo.total);

      console.info("this.uploadList[key].percent",key,value,upInfo.total,this.uploadList[key].percent)
      // this.uploadList[key].status = "active"
      upInfo.upSize = value
      if (upInfo.percent >= 100 || (upInfo && upInfo.upSize >= upInfo.total)){
        this.uploadList[key].status = "success"
        this.uploadList[key].percent  = 100
          console.log(upInfo);
      }

      // 页面数据强制刷新
      this.$forceUpdate();
    },

    uploadFinallyCount(){
      let cnt = 0
      for (let k in this.uploadList){
        if (this.uploadList[k].percent >= 100){
          cnt++
        }
      }
      return cnt
    },
    openRemoveModal(id){
      if (id == '' && this.selectedFruits.length==0) {
        return this.$message.error(this.ll[this.languageId].please_choose)
      }
      this.removeModalVisible = true
      this.deleteId = id
    },
    handleRemove(){
      let args = {}
      if (this.deleteId != "") {
        let ids = []
        ids.push(this.deleteId)
        args = JSON.stringify(ids)
      } else {
        args = JSON.stringify(this.selectedFruits)
      }
      fileRemove({"id":args}).then((response)=>{
        console.log("接口返回值：", response)
        this.getUserFileTypeList()
        this.goto()
        this.deleteId = ""
      })
      .finally(()=>{
        this.removeModalVisible = false
        this.deleteId = ""
      })
    },
    openRename(){
      if (this.selectedRowKeys.length === 1){
        if (this.addFolder){
          this.handleAddFolderCancle()
          this.selectedRowKeys = [this.selectedRowKeys[0]-1]
        }
        this.renameIndex = this.selectedRowKeys[0]
        this.renameValue = this.selectedNames[0]
        this.$nextTick(()=>{
          this.$refs.renameInputRef.focus()
        })
      }
      
    },
    handleRenameOK(){
      let path = this.navs.join("/")
      rename({path:path,oldName:this.selectedNames[0],newName:this.renameValue})
      .then(()=>{
        this.goto(path)
      })
      .finally(() => {
        this.handleRenameCancle()
      })
    },
    handleRenameCancle(){
      this.renameIndex = -1
    },
    openMvcpModal(move){
      if (this.selectedNames.length == 0){
        return
      }
      
      if (move){
        this.mvcpMove = true
        this.dirModalTitle="移动到"
        this.dirModalOkText="移动到此"
      }else{
        this.mvcpMove = false
        this.dirModalTitle="复制到"
        this.dirModalOkText="复制到此"
      }
      
      this.mvcpSource = []
      const path = this.navs.join("/")
      for (let name of this.selectedNames){
        this.mvcpSource.push(path + "/" + name)
      }

      this.dirModalNavs = [this.root]
      this.mvcpGetList(this.dirModalNavs.join("/"))
      //console.log(this.mvcpSource,this.dirModalVisible);
      this.dirModalVisible = true
    },
    mvcpGetList(path){
      list({path:path}).then(res => {
        this.dirModalData = [];
        for (let v of res.items){
          if (v.isDir){
            this.dirModalData.push(v)
          }
        }
        
        this.dirModalData.sort((a,b) =>{
          if ( !a.isDir && b.isDir){
            return 1
          }else if (a.isDir == b.isDir){
            return a.filename - b.filename
          }
          return -1
        })
        //console.log(this.dirModalData);
      })
    },
    mvcpGoto(index){
      if (index >= 0 && index < this.dirModalNavs.length ){
        this.dirModalNavs.splice(index+1)
        const path = this.dirModalNavs.join("/")
        this.mvcpGetList(path)
      }
    },
    mvcpGoback(){
      if (this.dirModalNavs.length > 1) {
        this.dirModalNavs.pop()
        const path = this.dirModalNavs.join("/")
        this.mvcpGetList(path)
      }
    },
    mvcpGonext(dir){
      if (dir !== ""){
        this.dirModalNavs.push(dir)
        const path = this.dirModalNavs.join("/")
        this.mvcpGetList(path)
      }
    },
    handleMvcp(){
      const args = {move:this.mvcpMove,source:this.mvcpSource,target:this.dirModalNavs.join("/")}
      mvcp(args)
      .then(()=>{
        this.goto()
      })
      .finally(()=>{
        this.dirModalVisible = false
      })
    },
    showDownload(){
      if (this.selectedRowKeys.length > 0){
        for (var idx of this.selectedRowKeys){
          if (this.resData.items[idx].isDir){
            return false
          }
        }
        return true
      }
      return false
    },

    // handleDownload(){
    //       const args = {id:JSON.stringify(this.selectedFruits),format:"png"}
    //       downloadFile(args).then(res =>{
    //         console.info("res.data",res.data.data)
    //         for (var item of res.data.data){
    //           console.info("item",item)
    //           // const blob = new Blob(item.path);
    //           // var downloadElement = document.createElement("a");
    //           // var href = window.URL.createObjectURL(blob);
    //           // downloadElement.href = href;
    //           // downloadElement.download = decodeURIComponent(item.name);
    //           // document.body.appendChild(downloadElement);
    //           // downloadElement.click();
    //           // document.body.removeChild(downloadElement);
    //           // window.URL.revokeObjectURL(href); 
    //         }
    //       })
    // },
    openShareModal(){
      if (this.selectedNames.length === 0){
        return
      }
      this.shareModalVisible = true;
      this.shareCreate = true;
      this.shareTime = 7
      this.shareTitle = "分享文件(夹):"
      this.sharedCopied = false
      if (this.selectedNames.length === 1){
        this.shareTitle += this.selectedNames[0]
      }else{
        this.shareTitle += this.selectedNames[0] + "等"
      }
    },
    handleShareCreate(){
      const args = {path:this.navs.join("/"),filename:this.selectedNames,deadline:this.shareTime}
      sharedCreate(args)
      .then((ret)=>{
        this.shareCreate = false;
        this.sharedRoute = ret.route;
        this.sharedToken = ret.sharedToken;
        this.sharedDeadline = ret.deadline;
        this.sharedCopyText = makeSharedLink(this.sharedRoute, this.sharedToken)
      })
      
    },
    userLogout(){
      storage.remove("Access-Token");
      setMachineModel("")
      setMachineNumber("")
      setTimeout(() => {
        this.$router.push({ name: 'login' })
      }, 500)
    },
    gotoSharedList(){
      const listUrl = this.$router.resolve({
        name:"sharedlist"
      })
      window.open(listUrl.href,'_blank')
    }
  },

}
</script>

<style>
body{
 font-family: PingFangSC,PingFang SC;
 background:#fff;
}
.webstie{
  color: #000;
}
.webstie:hover{
  text-decoration: underline;
  color:#275B52;
}
.custom-loading .el-loading-spinner .path {
  stroke: #275B52 !important;
}

.custom-loading .el-loading-spinner .circular {
  border-top-color: #275B52 !important;
  border-right-color: #275B52 !important;
  border-bottom-color: #275B52 !important;
  border-left-color: #275B52 !important;
}

.custom-loading .el-loading-text {
  color: #275B52 !important;
}



.loading-spinner .ant-spin-dot {
  font-size: 20px; 
}
.el-loading-spinner > div {
  border-color: #275B52 transparent transparent transparent;  /* 替换为你想要的颜色 */
}
.loading-spinner .ant-spin-dot-item {
  background-color: #275B52;
}
.ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 8px 8px 0 0;
}
.ant-spin-nested-loading > div > .ant-spin {
  /* margin-top:90px; */
  padding-top: 600px;
}
.ant-modal-body {
    padding: 30px 40px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
}
.canvas{
  width: 128px;
  height: 128px;
  border-radius: 8px;
  border: 1px solid #C6D5E1;
}

.weixinurl{
  width: 305px;
    /* height: 90px; */
    background: rgb(239, 242, 238);
    border-radius: 8px;
    color: rgb(0, 0, 0);
    margin-top: 40px;
    /* position: absolute; */
    margin: auto;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: 500;
}
.ant-progress-status-success .ant-progress-bg {
    background-color: #275B52;
}
.ant-progress-status-success .ant-progress-text {
    color: #275B52;
}
.ant-progress-text {
    display: inline-block;
    width: 2em;
    /* margin-left: 8px; */
    color: rgba(0, 0, 0, 0.45);
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
    word-break: normal;
}
.ant-progress-status-success .ant-progress-text {
    color: #275B52;
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
}

.radio-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* 可选: 添加一些底部间距 */
  width:40%;
}


.ant-btn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
    width: 80px;
    height: 34px;
}
.ant-btn:hover,.ant-btn:focus {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
}
.ant-btn-primary {
    color: #fff;
    background-color: #000;
    border-color: #000;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    height: 34px;
    width: 80px;
    font-size: 12px;
    border-radius: 2px;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

/* 修改选中颜色 */
.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner {
    border-color: #000;
}

.ant-radio-checked .ant-radio-inner::after {
    background-color: #000;
}

/* 修改悬停颜色 */
.ant-radio:hover .ant-radio-inner {
    border-color: #000;
}

.ant-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1px;
    border-radius: 100px;
    transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
    top: 2px;
    left: 2px;
    display: table;
    width: 12px;
    height: 12px;
    background-color: #275B52;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
}

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    pointer-events: auto;
}
.ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 30px;
}
</style>
<style scoped>
body{
 background-color: #fff;
}
.body{
  min-width: 1000px;
  overflow: auto;
}
#header{
  height:60px;
  line-height:60px;
  margin-bottom:10px;
}
.path{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:68px;
}

#upload_div{
  box-shadow: 0 1px 20px rgba(0,0,0,.2);
  border:2px;
  border-radius:8px;
  width:617px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 1000;
  background: white;
  overflow: hidden;
}

.upload_div_row{
  height:40px;
  line-height:40px;
  margin-bottom:10px;
  display: flex;
}

#download_div {
  box-shadow: 0 1px 20px rgba(0,0,0,.2);
  border:2px;
  border-radius:8px;
  width:617px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 1001;
  background: white;
  overflow: hidden;

  
}
.download_div_row {
  height:40px;
  line-height:40px;
  margin-bottom:10px;
  display: flex;
}

/* 滚动条宽度 */
::-webkit-scrollbar {
 width: 7px;
 height: 10px;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
 background-color: #a1a3a9;
 border-radius: 3px;
}

.new_header{
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;

  padding:0 25px;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('../../assets/pc/header_bg.png');
  background-size: auto 100%; /* 宽度自适应，高度与容器高度一致 */
  background-repeat: no-repeat; /* 确保图片不重复 */
  background-position: left; /* 可以设置背景图片居中显示 */
  background-color: #DDE2DA;
  min-width: 1000px;
}

.new_header_right{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color:#31343B;
}
.right_block{
  display: flex;
  align-items: center;
  margin-left: 25px;
  cursor: pointer;
}
.block_d{
  margin-left: 5px;
}
.content{
  display: flex;
  justify-content: space-between;
  height: 100vh;
}
.c-left{
  width: 223px;
  background-color: #EFF2EE;
}
.left-item{
  height: 68px;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.left-item-on {
  /* background-color: #C9CFC5; */
  background-color: #CBD1C7;
  position: relative; /* Ensure the pseudo-element is positioned relative to the item */
}

.left-item-on::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0; /* Position the arrow at the right edge */
  transform: translateY(-50%); /* Center the arrow vertically */
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 7px solid white; /* Adjust this for a left-pointing arrow */
}

.left-item-l{
  color: #23272A;
  font-size: 16px;
  font-weight: 600;
  display: flex;
    align-items: center;
}
.left-item-r{
  color:#23272A;
  font-size: 15px;
  margin-right: 10px;
}
.c-right{
  /* margin-right: 25px; */
  background: #fff;
  margin-left:200px;
  margin-top:88px;
  min-width: auto;
  overflow: hidden;
}
.border_right {
        position: relative;
        margin-right: 15px;
    }
.border_right::after {
  content: '';
    position: absolute;
    top: 20%;
    right: -15px;
    width: 2px;
    height: 60%;
    background-color: #D1D6DE;
    }
    .search-icon {
  cursor: pointer;
  color: #979BA2;
  width:16px;height:16px;
}

.custom-input {
  width: 214px;
  height: 42px;
  background-color: #F8F9F8;
  border-radius: 2px;
  font-size: 14px;
  color: #000000;
}
.custom-input:hover {
  background-color: #fff;
  border-color: #E0E5DE;
  box-shadow: 0 0 4px #275B52;
}
.custom-input:focus {
  background-color: #fff;
  border-color: #E0E5DE;
  box-shadow: 0 0 4px #275B52;
}

.border_right_d {
        position: relative;
    }


    .vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}



/* use css to set position of modal */
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

/*
// Use flex which not working in IE
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}
*/

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.loading-spinner{
  /* height: 200px; */
  /* top:90px; */
  /* height: calc(100vh -160px); */
  /* height: 1000px; */
 /* 为头部留出空间 */
  /* width: calc(100% - 200px); */
  /* height: calc(100vh - 88px); */
  /* overflow: hidden; */
  /* width:100%; */
  color:#275B52;
  margin-left: 25px;
  margin-right: 25px;


}
.loading-spinner .ant-spin-nested-loading,
.loading-spinner .ant-spin-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}




#sidebar {
  width: 200px;
  position: fixed;
  top: 88px; /* 保持在头部下面 */
  bottom: 0;
  background: #f4f4f4;
  overflow-y: auto;
}

.content_wrapper {
  margin-top: 90px; /* 为头部留出空间 */
  width: calc(100% - 200px);
  /* height: calc(100vh - 88px); */
  /* overflow: hidden; */
  width:100%;
}

.content_content {
  height: 100%;
  /* overflow-y: auto; */
  padding: 25px;
  padding-right: 0;
  padding-left: 0;
  margin-top: 90px; /* 为头部留出空间 */
}


</style>